import React from 'react'

const FeatureGallery = ({styles}) => {
  return (
    <div className={styles.featureGallery}>
      <span>Thanks for visiting!</span>
      <span>GainsTracker is a fitness app that gives you full control over how to track your fitness. It is currently under construction, so please check back later!</span>
    </div>
  )
}

export default FeatureGallery