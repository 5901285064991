import React, { useState } from 'react'
import TextInput from './TextInput'
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';

const Form = ({styles, dataToEdit, graphData, convertToObject, genSelectDefault}) => {
  const [charCount, setCharCount] = useState(0);
  const [nameExists, setNameExists] = useState(convertToObject(graphData));
  const [selectedValue, setSelectedValue] = useState(genSelectDefault)
  const [error, setError] = useState(null)
  const [waitingForResponse, setWaitingForResponse] = useState(false)

  const navigate = useNavigate();


  const handleDescription = (string) => {
    setCharCount(string.length);
  };

  const allValuesOkay = (data) => {
    if (!data[0].value || !selectedValue) { // If name and/or select are not valued, display an error and return false
      setError('To track something new, you need to provide at least a name and category')
      return false;
    } else {
      if (!dataToEdit && nameExists[data[0].value]) { // If a graph is not being edited and there already exists a graph with that name for the user, display an error and return false
        setError("You're already tracking something with that name. Please choose a different name")
        return false; // this will display an error
      } else if (dataToEdit && nameExists[data[0].value]) { // If a graph is not being edited and there already exists a graph with that name for the user...
        if (dataToEdit.name !== data[0].value) { // only display an error and return false if the graph's original name doesn't equal the value of the name field (checks for cases where the name goes unchanged)
          setError("You're already tracking something with that name. Please choose a different name")
          return false;
        }
      }
    }
    return true; // All values are okay!
  };

    const prepareData = (data) => {
    const values = {};
    values[`name`] = data[0].value;
    values[`type`] = selectedValue.value;
    values[`y_axis`] = data[1].value;
    values[`description`] = data[3].value;
    return values;
  };

  const createGraph = async (data) => {
    if (!allValuesOkay(data)) {
      return false;
    }
    setWaitingForResponse(true)
    const dataToSend = prepareData(data);
    const response = await fetch("https://api.gainstracker.app/home/add_graph", {
      method: "POST",
      body: JSON.stringify(dataToSend),
      credentials: "include",
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "https://gainstracker.app/",
      },
    });
    if (response.status !== 200) {
      setError("The server has encountered an error. Please try creating the graph again.")
      setWaitingForResponse(false)
      return;
    }
    const parsedResponse = await response.json();
    setWaitingForResponse(false)
    navigate("/graph/"+parsedResponse.graph_ID)
  };

  const updateGraph = async (data) => {
    if (!allValuesOkay(data)) {
      return false;
    }
    const dataToSend = prepareData(data);
    dataToSend.graph_ID = dataToEdit.graph_ID;
    const response = await fetch("https://api.gainstracker.app/home/update_graph", {
      method: "POST",
      body: JSON.stringify(dataToSend),
      credentials: "include",
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "https://gainstracker.app/",
      },
    });
    if (response.status !== 200) {
      console.log('response !== 200')
      setError("The server has encountered an error. Please try creating the graph again.")
      setWaitingForResponse(false)
      return;
    } else {
      console.log("response === 200")
      setWaitingForResponse(false)
      navigate(0);
    }
  };

  const selectStyles = {
    container: (provided) => ({
      ...provided,
      width: "calc(50% + 14px)"
    }),
    control: (provided) => ({
      ...provided,
      border: "solid black 1px",
      borderRadius: "8px",
      padding: "1px",
    })
  };
  return (
    <div className={styles.formOuter}>
      <span>{dataToEdit.graph_ID ? 'Editing dataset' : 'Adding new dataset'}</span>
      <form className={styles.newDataset}         
      onSubmit={(e) => {
          e.preventDefault();
          dataToEdit.graph_ID ? updateGraph(e.target) : createGraph(e.target);
        }}>
        <TextInput type={"Graph Name"} styles={styles} defaultValue={dataToEdit.name ? dataToEdit.name : null}/>
        <TextInput type={"Unit"} styles={styles} defaultValue={dataToEdit.yAxisName ? dataToEdit.yAxisName : null}/>
        <div className={styles.inputContainer}>
          <label htmlFor={null}>
            <span>Category</span>
            <span className={styles.spacer}></span>
          </label>
          <Select 
          options={
            [{value:"weight-training" , label: "Weight Training"},
            {value:"calisthenics" , label: "Calisthenics"},
            {value:"cardio" , label: "Cardio"},
            {value:"biometrics" , label: "Biometrics"},
            {value:"other" , label: "Other"}]
          }
          defaultValue={selectedValue}
          onChange={(option) => setSelectedValue(option)}
          styles={selectStyles}
          />
        </div>
        <div className={styles.inputContainer} id={styles.textarea}>
          <label htmlFor="description">
            <span>Description</span>
            <span className={styles.spacer}></span>
          </label>
          <textarea
            name="description"
            id="description"
            type="textfield"
            autoComplete="off"
            placeholder="Write here..."
            maxLength="100"
            defaultValue={dataToEdit.description ? dataToEdit.description : null}
            ></textarea>
        </div>
        <button type="submit">
              {dataToEdit.graph_ID ? 'Commit changes' : 'Create dataset'}
        </button>
      </form>
    </div>
  )
}

export default Form
