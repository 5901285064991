import React, { useState } from 'react'

const NewCheckbox = ({styles, submit, handleAdding, editing}) => {
  const [title, setTitle] = useState(editing ? editing.title : '')
  const [content, setContent] = useState(editing ? editing.content : '')

  const optionSelect = () => {
    if (editing) {
      submit(title, content, editing.checklist_ID, editing.checked)
    } else {
      submit(title, content)
    }
  }
  
  return (
    <div className={styles.newCheckbox}>
        <div className={styles.inputContainer}>
            <input 
            onChange={(e) => setTitle(e.target.value)} 
            className={styles.title} 
            id='title' 
            type='text' 
            placeholder='Add a title...' 
            defaultValue={editing ? editing.title : ''}
            maxLength={50}></input>
            <input 
            onChange={(e) => setContent(e.target.value)} 
            className={styles.content} 
            id='content' 
            type='text' 
            placeholder='Add a description...' 
            defaultValue={editing ? editing.item : ''}
            maxLength={50}></input>
        </div>
        <div className={styles.buttonContainer}>
            <svg onClick={() => {optionSelect()}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
            <svg onClick={() => {handleAdding()}}xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
        </div>
    </div>
  )
}

export default NewCheckbox