import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import LoginForm from './LoginForm';


const Login = ({styles}) => {
  const navigate = useNavigate();
  
  const [error, setError] = useState(null);
  const [waitingForResponse, setWaitingForResponse] = useState(false)

  const prepareData = (userInput) => {
    const data = {};
    data["username"] = userInput[0].value;
    data["password"] = userInput[1].value;
    return data;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setWaitingForResponse(true);
    const data = prepareData(e.target);
    await fetch("https://api.gainstracker.app/users/sign_in", {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "gainstracker.app",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        data.errType ? setError(data.errType) : window.location.reload();
      }
      )
      .catch((error) => console.error(error));
    setWaitingForResponse(false);
  };
  
  return (
    <div className={styles.login}>
      <div className={styles.welcome}>
        <span>Welcome to GainsTracker!</span>
        <span>a customizable fitness app</span>
      </div>
      <LoginForm 
      handleSubmit={handleSubmit} error={error} 
      waitingForResponse={waitingForResponse}
      styles={styles}
      />
    </div>
  )
}

export default Login