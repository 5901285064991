import React from 'react'
import CustomSelect from '../sitewide/CustomSelect';
import CardContainer from './CardContainer';

const CardContainerOuter = ({styles, handleTypeSwitch, untracked, sortedData, graphData, setEditing, setWaitingForResponse, selectStyles, options}) => {
    return (
      <div className={styles.containerOuter}>
          <div className={styles.select}>
              <div className={styles.selectInner}>
                  <span>{untracked ? 'Untracked' : 'Tracked'}</span>
                  {untracked ? <CustomSelect switchBehavior={handleTypeSwitch} styles={selectStyles} options={options} id={'category'}/>: ''}
              </div>
              {untracked ? 
              <div className={styles.addGraph} onClick={() => {setEditing(true)}}>
                  <svg xmlns="http://www.w3.org/2000/svg" 
                  height="1.5em" 
                  viewBox="0 -960 960 960" 
                  width="1.5em">
                  <path 
                  fill='#a3a2a4'
                  d="M450-200v-250H200v-60h250v-250h60v250h250v60H510v250h-60Z"/>
                  </svg>
                  Add a new dataset
              </div>
              :
              ''
              }
          </div>
          <CardContainer 
          styles={styles} 
          cards={untracked ? sortedData.untracked : sortedData.tracked} 
          graphData={graphData}
          setEditing={setEditing}
          setWaitingForResponse={setWaitingForResponse}
          />
      </div>
      )
  }
export default CardContainerOuter