import React, { useState } from 'react'

const SettingsForm = ({styles, mode, submitRoute, setParentError, currentValues, setParentValues, setSuccess}) => {
  const [error, setError] = useState(null)
  const [inputs, setInputs] = useState({new: '', confirm: ''})

  const changeInput = (type, value) => {
    if (type === 'new') {
        setInputs((prev) => ({...prev, new: value}))
    } else {
        setInputs((prev) => ({...prev, confirm: value}))
    }
  }

  const checkValuesAreValued = (e) => {
    let count = 0;
    for (const target of e.target) {
        if (target.value === '') {
            if (target.textContent !== `Update ${mode}`) {
                setError({errIndex: count, errText: 'Please fill out this field.'})
                console.log(count)
                return false;
            }
        }
        count += 1;
    }
    setError(null)
    return true;
  }

  const checkValuesAreEqual = (e) => {
    if (e.target[0].value !== e.target[1].value) {
        setError({errIndex: 0, errText: `${mode === 'password' ? 'Passwords' : 'Emails'} do not match`})
        return false;
    } else {
        setError(null)
        return (e.target[0].value === e.target[1].value)
    }
  }

  const update = async (e) => {
    e.preventDefault();
    if (checkValuesAreValued(e) && checkValuesAreEqual(e)) {
        const dataToUpdate = ({currentEmail: currentValues.email, currentPassword: currentValues.password, new: e.target[1].value})
        const response = await fetch(`https://api.gainstracker.app/settings/${submitRoute}`, {
            method: "POST",
            body: JSON.stringify(dataToUpdate),
            credentials: "include",
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "Access-Control-Allow-Origin": "gainstracker.app",
            },
          });
          if (response.status !== 200) {
            const parsedResponse = await response.json();
            parsedResponse.parent ? setParentError({errIndex: parsedResponse.errIndex,  errText: parsedResponse.errText}) : setError({errIndex: parsedResponse.errIndex,  errText: parsedResponse.errText})
            return;
        } else {
            setSuccess({type: mode === 'email' ? 'Email' : 'Password', displayed: true})
            setParentValues({email: '', password: ''})
            setInputs({new: '', confirm: ''})
            setError(null)
            setParentError(null)
            console.log('ok!!') // this needs to display some kind of confirmation via state or w/e
        }
    }
  }

  return (
    <form className={styles.formInner} onSubmit={(e) => update(e)}>
    {mode === 'password' || mode === 'email' ?
        <div>
            <label htmlFor={`new-${mode}`}>New {mode} {error ? error.errIndex === 0 ? <span className={styles.error}>{error.errText}</span> : '' : ''}</label>
            <input name={`new-${mode}`} id={`new-${mode}`} type={mode === 'password' ? 'password' : 'email'} value={inputs.new} onChange={(e) => changeInput('new', e.target.value)}></input>
        </div>
     : ''}
    <div>
        <label htmlFor={`confirm-${mode}`}>Confirm {mode} {error ? error.errIndex === 1 ? <span className={styles.error}>{error.errText}</span> : '' : ''}</label>
        <input name={`confirm-${mode}`} id={`confirm-${mode}`} type={mode === 'password' ? 'password' : 'email'} value={inputs.confirm} onChange={(e) => changeInput('confirm', e.target.value)}></input>
    </div>
    <button>{mode === 'password' || mode === 'email' ? `Update ${mode}` : `Delete account`}</button>
  </form>
  )
}

export default SettingsForm
