import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { sx } from '../../style-modules/sx';

const DateNotes = ({styles, changeDate}) => {
  return (
        <div className={styles.dateNotes}>
        <div className={styles.date}>
        <div className={styles.header}>Hi, User!</div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDatePicker 
                disableFuture={true}
                className={styles.test}
                sx={sx}
                onChange={(e) => {changeDate(e.$d)}}
                slotProps={{actionBar: {className: styles.hidden}, toolbar: {className: styles.hidden}}}
                />
            </LocalizationProvider>
        </div>
        <div className={styles.notes}>
            <span>Personal Notes</span>
            <textarea placeholder='Write here...'></textarea>
        </div>
    </div>
)
}

export default DateNotes