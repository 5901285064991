import React, { useEffect } from "react";
import Login from './Login'
import FeatureGallery from './FeatureGallery'
import useVerify from '../../useVerify';
import { useNavigate } from "react-router-dom";
import styles from '../../style-modules/landing/_index.module.scss'

const Landing = () => {
  const { status, loading } = useVerify("/verify_user");
  const navigate = useNavigate();

  useEffect(() => {
    if (status === 200) {
      navigate("/home");
    }
  }, [status, navigate]);

  if (loading) {
    return (
      <div>Loading...</div>
    )
  }
  return (
    <div className={styles.landing}>
      <Login styles={styles}/>
      <FeatureGallery styles={styles}/>
    </div>
  )
}

export default Landing