import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const [waitingForResponse, setWaitingForResponse] = useState(false); // Is the component waiting on the server to respond
  const [nameEmpty, setNameEmpty] = useState(false); // Is the username field filled out
  const [nameTaken, setNameTaken] = useState(false); //  Does the username provided exist in the database
  const [emailTaken, setEmailTaken] = useState(false); //  Does the email provided exist in the database
  const [password, setPassword] = useState(""); //  Track the value of the password field for comparison with confirm and regex
  const [confirm, setConfirm] = useState(""); //  Track the value of the confirm field for comparison with password
  const [invalid, setInvalid] = useState(false); //  Does the value of password match confirm
  const [meetsRegex, setMeetsRegex] = useState(true); //  Does the password match the regex pattern
  const [isEmail, setIsEmail] = useState(true); //  Does the email match standard email formats
  const [error, setError] = useState(null); // A string representation of an error.

  const passwordRegex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/; //  Does the password have 8<= chars, an uppercase and lowercase char, a number, and symbol?
  const usernameRegex = /^[a-zA-Z0-9_]*$/; // Does the username only contain letters, numbers and underscores?
  const emailRegex =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/; // Is the email structurally valid?

  const navigate = useNavigate();

  const handlePasswordChange = (e) => {
    // Check if password value matches confirm value. Only show an error if confirm is valued.
    if (confirm) {
      if (confirm === e.target.value) {
        setInvalid(false);
      } else {
        setInvalid(true);
      }
    }
    setPassword(e.target.value);
  };

  const handleConfirmChange = (e) => {
    // Check if confirm value matches password value.
    if (e.target.value === "") {
      setInvalid(true);
      setConfirm("");
    } else {
      if (password === e.target.value) {
        setInvalid(false);
        setConfirm(e.target.value);
      } else {
        setInvalid(true);
        setConfirm(e.target.value);
      }
    }
  };

  const handleUsernameChange = async (e) => {
    // Ping the database to see if the requested username already exists
    if (e.target.value === "" || !usernameRegex.test(e.target.value)) {
      setNameEmpty(true);
      return;
    }
    setNameEmpty(false);
    setWaitingForResponse(true);
    fetch("https://api.gainstracker.app/checks/username/" + e.target.value)
      .then((response) => response.json())
      .then((data) => {
        if (data.username) {
          setWaitingForResponse(false);
          setNameTaken(true);
        } else {
          setWaitingForResponse(false);
          setNameTaken(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEmailChange = async (e) => {
    // Ping the database to see if the requested email already exists
    if (!emailRegex.test(e.target.value)) {
      setIsEmail(false);
      return;
    } else {
      setIsEmail(true);
    }

    setWaitingForResponse(true);

    fetch("https://api.gainstracker.app/checks/email/" + e.target.value)
      .then((response) => response.json())
      .then((data) => {
        if (data.email) {
          setWaitingForResponse(false);
          setEmailTaken(true);
        } else {
          setWaitingForResponse(false);
          setEmailTaken(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setWaitingForResponse(false);
      });
  };

  const checkData = (formData) => {
    // Ensure email and username have values (password is verfied as not empty using regex)
    if (!formData[0].value) {
      setIsEmail(false);
      return false;
    }
    if (!formData[1].value) {
      setNameEmpty(true);
      return false;
    }
    if (!formData[3].value) {
      setInvalid(true);
      return false;
    }
    return true;
  };

  const prepareData = (formData) => {
    // Create an object out of form data to be used in POST request
    const data = {};
    data[`email`] = formData[0].value;
    data[`username`] = formData[1].value;
    data[`password`] = formData[2].value;
    return data;
  };

  const handleSubmitUser = async (e) => {
    // Create a new user in the database with data supplied by the form
    e.preventDefault(e);
    if (!passwordRegex.test(password)) {
      setMeetsRegex(false);
      return;
    } else {
      setMeetsRegex(true);
    }
    if (!checkData(e.target)) {
      return;
    }
    setWaitingForResponse(true);
    const data = prepareData(e.target);
    await fetch("https://api.gainstracker.app/users/sign_up", {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "https://gainstracker.app/",
      },
    }).then((response) =>
      response.status === 200 ? navigate("/") : setError("The server encountered an error. Please try again.")
    );
    setWaitingForResponse(false);
  };

  return (
    <div className="sign-up">
      {error ? <div className="error" id="sign-up">{error}</div> : ''}
      <form
        onSubmit={(e) => {
          handleSubmitUser(e);
        }}
      >
        <div className="form-input">
          <input
            name="email"
            id={!isEmail || emailTaken ? "invalid" : "email"}
            onChange={(e) => handleEmailChange(e)}
            type="text"
            autoComplete="off"
            placeholder=" "
          ></input>
          <label htmlFor="password">EMAIL</label>
          {!isEmail || emailTaken ? (
            <span id="error">
              {emailTaken
                ? "That email address is already in use."
                : "Make sure your email is written correctly."}
            </span>
          ) : (
            ""
          )}
        </div>
        <div className="form-input">
          <input
            name="username"
            id={nameEmpty || nameTaken ? "invalid" : "username"}
            onChange={(e) => handleUsernameChange(e)}
            type="text"
            autoComplete="off"
            placeholder=" "
          ></input>
          <label htmlFor="password">USERNAME</label>
          {nameEmpty || nameTaken ? (
            <span id="error">
              {nameEmpty
                ? "Please enter a username (only letters and numbers)"
                : "That name is taken."}
            </span>
          ) : (
            ""
          )}
        </div>
        <div className="form-input">
          <input
            name="password"
            id={invalid ? "invalid" : "password"}
            type="password"
            autoComplete="off"
            placeholder=" "
            onChange={(e) => handlePasswordChange(e)}
          ></input>
          <label htmlFor="password">PASSWORD</label>
          {!meetsRegex ? (
            <span className="password-help" id="error">
              Password must be at least 8 characters and consist of one
              uppercase character, one lowercase character, and a symbol
              (#?!@$%^&*-).
            </span>
          ) : (
            ""
          )}
        </div>
        <div className="form-input">
          <input
            name="confirm"
            id={invalid ? "invalid" : "confirm"}
            type="password"
            autoComplete="off"
            placeholder=" "
            onChange={(e) => handleConfirmChange(e)}
          ></input>
          <label htmlFor="password">CONFIRM PASSWORD</label>
          {invalid ? <span id="error">Passwords don't match.</span> : ""}
        </div>
        <button
          type="submit"
          disabled={
            nameEmpty ||
            nameTaken ||
            invalid ||
            emailTaken ||
            !isEmail ||
            waitingForResponse
              ? true
              : false
          }
          id={
            nameEmpty ||
            nameTaken ||
            invalid ||
            emailTaken ||
            !isEmail ||
            waitingForResponse
              ? "disabled"
              : ""
          }
        >
          Sign Up
        </button>
        {waitingForResponse ? (
          <span className="waiting">Checking name, email...</span>
        ) : (
          ""
        )}
      </form>
    </div>
  );
};

export default SignUp;
