/**
 * Style overrides for MUI's StaticDatePicker. These changes are responsible for ensuring responsive behavior on desktop.
 */

export const sx = {
        '& .MuiPickersLayout-contentWrapper': {
            height: "100%"
        },
        '& .MuiPickersArrowSwitcher-root': {
            '& button': {
                '& .MuiSvgIcon-root': {
                    width: "2em",
                    height: "2em"
                },
            }
        },
        '& .MuiPickersCalendarHeader-labelContainer': {
        fontSize: "clamp(16px, .9vw, 2rem)",
        fontFamily: 'Poppins', 
        },
        '& .MuiDateCalendar-root': {
            backgroundColor: '#fafafa',
            borderRadius: '10px',
            maxHeight: "unset",
            height: '100%',
            width: '90%'
        },
        '& .MuiPickersCalendarHeader-root': {
            width: "80%",
            margin: "5% auto 5%",
            '& .MuiSvgIcon-root': {
                width: "1em",
                height: "1em"
            }
        },
        '& .MuiPickersDay-hiddenDaySpacingFiller': {
            margin: "0"
        },
        '& .MuiDayCalendar-header': {
            height: "10%",
            justifyContent: "space-around",
        },
        '& .MuiDayCalendar-weekContainer': {
            justifyContent: "space-around",
        },
        '& .MuiDayCalendar-slideTransition': {
            height: "90%",
        },
        '& .MuiDateCalendar-viewTransitionContainer': {
            flexGrow: "1",
            width: "85%",
            alignSelf: "center",
            '& div': {
                height: "100%",
                '& > div': {
                    height: "95%",
                },
                '& > .MuiDayCalendar-header': {
                    height: "10%"
                },
                '& .MuiTypography-root': {
                    fontSize: "clamp(16px, .9vw, 2rem)",
                },
                '& > .MuiDayCalendar-weekContainer': {
                    height: 'initial'
                },
                '& .MuiDayCalendar-monthContainer': {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly"
                },
                '& button': {
                    fontSize: "clamp(16px, .9vw, 2rem)",
                    height: "36px",
                    width: "36px",
                    margin: "0"
                },
                '& .MuiPickersYear-root': {
                    height: "fit-content",
                    '& button': {
                        height: "auto",
                        width: "auto",
                        margin: "auto"
                    },
                },
                '& .MuiYearCalendar-root': {
                    width: "100%"
                }
            }
        },
        '& button': {
            fontFamily: 'Poppins',
            fontWeight: 100,
            fontSize: "clamp(14px, .6vw, 1.75rem);"
        },
        '& .MuiPickersDay-root.Mui-selected': {
            backgroundColor: '#6750a4',
        },
        '& .MuiPickersDay-root.Mui-selected:focus': {
            backgroundColor: '#6750a4',
        },
        '& .Mui-selected:hover': {
            backgroundColor: '#6750a4',
        },
        '& .Mui-selected': {
            backgroundColor: '#6750a4',
        },
        '& .Mui-selected:focus': {
            backgroundColor: '#6750a4',
        }
}