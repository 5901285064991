import React, { useState } from 'react'
import Sidebar from '../sitewide/Sidebar'
import styles from '../../style-modules/settings/_index.module.scss'
import EmailPassword from './EmailPassword';

const Settings = () => {
  const [activeTab, setActiveTab] = useState('Email and password');
  const genPage = () => {
    if (activeTab === 'Email and password') {
        return (<EmailPassword styles={styles}/>)
    } else if (activeTab === 'Dark mode') {
        return (<div>dork mode lol</div>)
    } else if (activeTab === 'Delete') {
        return (<div>delete</div>)
    }
  }
  return (
    <div className={styles.settings}>
        <Sidebar />
        <div className={styles.submenuContainer}>
            <div className={styles.header}>User settings</div>
            <div className={styles.submenu}>
                <span id={activeTab === 'Email and password' ? styles.active : ''} onClick={() => {setActiveTab('Email and password')}}>Email and password</span>
                <span id={activeTab === 'Dark mode' ? styles.active : ''} onClick={() => {setActiveTab('Dark mode')}}>Dark or light mode</span>
                <span id={activeTab === 'Delete' ? styles.active : ''} onClick={() => {setActiveTab('Delete')}} className={styles.delete}>Delete account</span>
            </div>
        </div>
        <div className={styles.content}>
            <div className={styles.contentHeader} id={activeTab === 'Delete' ? styles.active : ''}>
                <div>{activeTab}</div>
            </div>
            {genPage()}
        </div>
    </div>
  )
}

export default Settings
