import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function useVerify(url) {
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    const constructedUrl = "https://api.gainstracker.app" + url;
    fetch(constructedUrl, {
      credentials: "include",
    })
      .then((response) => {
        response.status !== 200 ? navigate("/") : setStatus(response.status);
        response.json().then(setData);
      })
      .then((parsed) => {
        setData(parsed);
        setLoading(false);
      });
  }, [url, navigate]);

  return { status, loading, data };
}

export default useVerify;
