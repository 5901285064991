import React from 'react'

const TextInput = ({type, styles, defaultValue}) => {
  return (
    <div className={styles.inputContainer}>
        <label htmlFor={type}>
            <span>{type}</span>
            <span className={styles.spacer}></span>
        </label>
        <input
        name={type}
        id={type}
        type="text"
        autoComplete="off"
        placeholder="Write here..."
        defaultValue={defaultValue}
        ></input>
    </div>
)
}

export default TextInput