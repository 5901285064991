import React from 'react'

const DeleteOverlay = ({styles, deleteGraph, setDeleting}) => {
  return (
    <div className={styles.deleteOverlay}>
        <span>Are you sure you want to delete this dataset? This cannot be undone.</span>
        <div className={styles.buttons}>
            <span onClick={() => {deleteGraph()}}>Yes</span>
            <span onClick={() => {setDeleting(false)}}>No</span>
        </div>
    </div>
  )
}

export default DeleteOverlay