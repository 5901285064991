import React from 'react'
import Select from 'react-select';

const CustomSelect = ({switchBehavior, styles, options, id}) => {
    return (
        <Select 
        name={id}
        id={id}
        options={options}
        onChange={(e) => {switchBehavior(e.value)}}
        styles={styles}
        ></Select> 
    )
}

export default CustomSelect