import React from 'react'
import { useLocation } from 'react-router-dom'
import styles from '../../style-modules/_header.module.scss'

const Header = () => {
    const handleSignOut = async () => {
        const response = await fetch("https://api.gainstracker.app/users/sign_out", { // MAKE THIS REDIRECT TO HOME ON SUCCESS BTW AND HAVE AN ERROR IF IT DOES NOT
          method: "POST",
          credentials: "include",
          withCredentials: true,
          headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "https://gainstracker.app/",
        },
      })
      console.log(response)
      return false;
      }
    const location = useLocation()

    return (
    <div className={styles.header}>
    <span>
        <a href="http://gainstracker.app">GainsTracker</a>
    </span>
    {!(location.pathname === '/') && !(location.pathname === '/sign-up') ? 
    <span onClick={() => {handleSignOut()}}>logout</span> : 
    ''}
    </div>
  )
}

export default Header