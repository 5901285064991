import React from 'react'
import styles from '../../style-modules/_add.module.scss'
const Add = ({method}) => {
  return (
    <div className={styles.addGraph} onClick={() => {method()}}>
        <svg xmlns="http://www.w3.org/2000/svg" 
        height="1.5em" 
        viewBox="0 -960 960 960" 
        width="1.5em">
        <path 
        fill='#a3a2a4'
        d="M450-200v-250H200v-60h250v-250h60v250h250v60H510v250h-60Z"/>
        </svg>
        Add
    </div>  
)
}

export default Add