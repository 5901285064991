import React, { useState } from 'react'
import SettingsForm from './SettingsForm';

const EmailPassword = ({styles}) => {
  const [error, setError] = useState(null)
  const [inputs, setInputs] = useState({email: '', password: ''})
  const [success, setSuccess] = useState({type: '', displayed: false})
  
  const changeInput = (type, value) => {
    if (type === 'email') {
        setInputs((prev) => ({...prev, email: value}))
    } else {
        setInputs((prev) => ({...prev, password: value}))
    }
  }
  if (success.displayed) {
    setTimeout(() => {
        setSuccess({type: '', displayed: false})
    }, 2500)
  } 
  return (
    <div className={styles.contentInner}>
        <div className={styles.directions}>To change your email or password, enter your current email and password along with what you want to change them to.</div>
        <div className={styles.formOuter}>
            <div className={styles.currentOuter}>
                <div className={styles.currentInner}>
                    <label htmlFor={`current-email`}>Current Email* {error ? error.errIndex === 0 ? <span className={styles.error}>{error.errText}</span> : '' : ''}</label>
                    <input name={`current-email`} id={`current-email`} type='email' value={inputs.email} onChange={(e) => changeInput('email', e.target.value)}></input>
                </div>
                <div className={styles.currentInner}>
                    <label htmlFor={`current-password`}>Current Password* {error ? error.errIndex === 1 ? <span className={styles.error}>{error.errText}</span> : '' : ''}</label>
                    <input name={`current-password`} id={`current-password`} value={inputs.password} type='password' onChange={(e) => changeInput('password', e.target.value)}></input>
                </div>
            </div>
            <div className={styles.formInner}>
                <SettingsForm styles={styles} mode={'email'} submitRoute={`update_email`} setParentError={setError} setParentValues={setInputs} currentValues={inputs} setSuccess={setSuccess} />
                <SettingsForm styles={styles} mode={'password'} submitRoute={`update_password`} setParentError={setError} setParentValues={setInputs} currentValues={inputs} setSuccess={setSuccess} />
                {success.displayed ? <span className={styles.success}>{`${success.type} successfully changed`}</span> : ''}
            </div>
        </div>
    </div>

)
}

export default EmailPassword
