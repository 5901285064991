import React, { useEffect, useState } from 'react'
import Sidebar from '../sitewide/Sidebar';
import styles from '../../style-modules/home/_index.module.scss'
import DateNotes from './DateNotes';
import Checklist from './Checklist';
import useVerify from '../../useVerify';

const Home = () => {
  const normalizeToday = (date) => { // maybe make this accessible to multiple components because a few of them can use it
    const today = date ? new Date(date) : new Date()
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0')
    const day = String(today.getDate()).padStart(2, '0');
    return (`${year}-${month}-${day}T00:00:00`)
  }
  const [waitingForResponse, setWaitingForResponse] = useState(false)
  const [today, setToday] = useState(normalizeToday()) // if the user never selects a date, default to today being selected
  const { data, loading } = useVerify(`/home/get_home_data?date=${new Date(today).getTime()}`)

  const changeDate = (date) => {
    setToday(normalizeToday(date));
  }
  // add a use effect that retrieves data each time the date changes. the date basically mediates everything child oi this component
  useEffect(() => {
    console.log(today)
  }, [today])
    return (
      <div className={styles.home}>
          <Sidebar />
          {/* break these direct children out into smaller components once you've got the shape figured out */}
          
          <DateNotes styles={styles} changeDate={changeDate}/>
          <div className={styles.dataEntry}>
  
          </div>
          {!data ? <div className={styles.loading}>loading...</div> 
          : 
          <div className={styles.checklistContainer}>
            <Checklist 
            mode={'Daily'}
            styles={styles} 
            waitingForResponse={waitingForResponse} 
            setWaitingForResponse={setWaitingForResponse}
            normalizeToday={normalizeToday}
            today={today}
            data={data}
            />
            <Checklist 
            mode={'Weekly'}
            styles={styles} 
            waitingForResponse={waitingForResponse} 
            setWaitingForResponse={setWaitingForResponse}
            normalizeToday={normalizeToday}
            today={today}
            data={data}
            />
          </div>
          }
      </div>
    )
}

export default Home