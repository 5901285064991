import React, { useState } from 'react'
import NewCheckbox from './NewCheckbox'

const Checkbox = ({item, title, checked, checklist_ID, styles, updateCheckbox, deleteCheckbox}) => {
  const [optionsShown, setOptionsShown] = useState(false)
  const [editing, setEditing] = useState(false)
  
  const handleClick = () => {
    checked === 0 ? checked = 1 : checked = 0
    updateCheckbox(title, item, checklist_ID, checked)
  }

  const handleEdit = () => {
    if (!editing) {
      setEditing({title: title, item: item, checked: checked, checklist_ID: checklist_ID})
    } else {
      setEditing(false)
    }
  }

  const handleOptions = () => {
    optionsShown ? setOptionsShown(false) : setOptionsShown(true)
  }

  if (editing) {
    return (
      <NewCheckbox styles={styles} submit={updateCheckbox} handleAdding={handleEdit} editing={editing} />
    )
  }

  return (
    <div className={styles.checkbox}>
      <div className={styles.text}>
        <div className={styles.title}>{title}</div>
        <div className={styles.content}>{item ? item : 'No description...'}</div>
      </div>
      <div className={styles.buttonContainer}>
        <div className={styles.interface}>
          <div className={styles.options} id={optionsShown ? styles.active : ''}>
            <span onClick={() => {handleEdit()}}>Edit</span>
            <span onClick={() => {deleteCheckbox(checklist_ID)}}>Delete</span>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" 
          height="1.5em" 
          viewBox="0 -960 960 960" 
          width="1.5em"
          onClick={() => {handleOptions()}}
          >
            <path 
            
            d="M479.788-192Q450-192 429-213.212q-21-21.213-21-51Q408-294 429.212-315q21.213-21 51-21Q510-336 
            531-314.788q21 21.213 21 51Q552-234 530.788-213q-21.213 21-51 21Zm0-216Q450-408 
            429-429.212q-21-21.213-21-51Q408-510 429.212-531q21.213-21 51-21Q510-552 
            531-530.788q21 21.213 21 51Q552-450 530.788-429q-21.213 21-51 21Zm0-216Q450-624 
            429-645.212q-21-21.213-21-51Q408-726 429.212-747q21.213-21 51-21Q510-768 531-746.788q21 21.213 21 
            51Q552-666 530.788-645q-21.213 21-51 21Z"/>
          </svg>
        </div>
        <input onClick={() => handleClick()} type='checkbox' defaultChecked={checked === 0 ? false : true} id={title} name={title}></input>
      </div>
    </div>
  )
}

export default Checkbox