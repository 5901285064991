import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./new-components/sitewide/Header"
import Landing from "./new-components/landing/Landing";
import Graphs from "./new-components/graphs/Graphs"
import Home from "./new-components/home/Home";
import SignUp from './components/landing/SignUp'
import Settings from "./new-components/settings/Settings";


const RouteSwitch = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/home" element={<Graphs />} />
        <Route path="/test" element={<Home />} />
        <Route path="/settings" element={<Settings />} />
        {/* <Route path="/graph/:graph_ID" element={<GraphMain />} /> */}
        {/* <Route path="/activate/:verifyCode" element={<Activated />} /> */}
        {/* <Route path="/nutrition" element={<NutritionMain />} /> */}
        {/* <Route path="*" element={<PageNotFound />} /> */}
      </Routes>
    </BrowserRouter>
  );
};

export default RouteSwitch;
