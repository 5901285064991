import React from 'react'
import Card from './Card'

const CardContainer = ({styles, cards, graphData, setEditing, setWaitingForResponse}) => {

  const convertDate = (integer) => {
    if (typeof(integer) === String) {
      return 'No data'
    }
    return new Date(parseInt(integer)).toLocaleString().split(",")[0];
  };

  return (
    <div className={styles.container}>
      {cards.map((card) => (
        <Card 
        key={card.graph_ID} 
        info={card} 
        styles={styles} 
        convertDate={convertDate} 
        graphData={graphData} 
        setEditing={setEditing} 
        setWaitingForResponse={setWaitingForResponse}
        />
      ))}
    </div>
  )
}

export default CardContainer