import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRef } from "react";
import Sidebar from '../sitewide/Sidebar'
import add from '../../assets/add.svg'
import styles from '../../style-modules/graphs/_index.module.scss';
import CardContainerOuter from "./CardContainerOuter";
import New from "../new/New";

const Graphs = () => {
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState("all");
  const [userData, setUserData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState(null);
  const [waitingForResponse, setWaitingForResponse] = useState(false)
  
  const params = useParams();
  const navigate = useRef(useNavigate());
  const today = new Date().toLocaleString().split(",")[0];

  const options = [{value:"all" , label: "All"},
  {value:"weight-training" , label: "Weight Training"},
  {value:"calisthenics" , label: "Calisthenics"},
  {value:"cardio" , label: "Cardio"},
  {value:"biometrics" , label: "Biometrics"},
  {value:"other" , label: "Other"}]
  const selectStyles = {
      container: (provided) => ({
        ...provided,
        borderRadius: "10px",
        fontSize: "clamp(16px, 1vw, 2rem)",
        marginLeft: "1em",
        width: "10em",
      }),
      control: (provided) => ({
          ...provided,
          borderRadius: "10px",
          height: "2.5em",
          minHeight: "40.35px"
      }),
      indicatorSeparator: (provided) => ({
          ...provided,
          backgroundColor: "transparent"
      }),
  };
  
  const handleError = (status) => {
    if (status === 403) {
      navigate.current("/")
    }
    setError("The server encountered an error. Please refresh the page or try again later.")
    setLoading(false)
    setGraphData(null)
  }
  
  useEffect(() => {
    fetch("https://api.gainstracker.app/home", {
      credentials: "include",
    }).then((response) => {
      response.status !== 200
        ? handleError(response.status) // have this set an error or do whatever
        : response.json().then((data) => {
            setUserData(data.user);
            setGraphData(data.graphs);
            setLoading(false);
          });
    });
  }, []);

  const handleEdit = () => {
    editing ? setEditing(false) : setEditing(true);
  }

  const handleResend = async () => {
    await fetch(
      "https://api.gainstracker.app/activate/resend/" +
        userData.user.user_ID +
        "/" +
        userData.user.email
    );
  };

  
  const handleTypeSwitch = (type) => {
    setType(type);
  };

  const convertDate = (integer) => {
    return new Date(parseInt(integer)).toLocaleString().split(",")[0];
  };

  if (loading || !userData) {
    return (
        <div>Loading...</div>
    );
  }
  if (userData.active) {
    const sortedData = Object.keys(graphData).reduce(
        (sorted, index) => {
          if (type === "all" || type === graphData[index].type) {
            if (today === convertDate(graphData[index].last_updated)) {
              sorted.tracked.push(graphData[index]);
            } else {
              sorted.untracked.push(graphData[index]);
            }
          }
          return sorted;
        },
        { tracked: [], untracked: [] }
      );
      return (
        <div className={styles.home}>
            <Sidebar />
            {editing ?
              <div className={styles.content}>
                <New 
                dataToEdit={editing}
                graphData={graphData}
                navigate={navigate}
                />
              </div>
            :
            <div className={styles.content}>
              <CardContainerOuter 
              styles={styles} 
              handleTypeSwitch={handleTypeSwitch} 
              untracked={true} 
              sortedData={sortedData} 
              graphData={graphData}
              setEditing={setEditing}
              setWaitingForResponse={setWaitingForResponse}
              options={options}
              selectStyles={selectStyles}
              />
              <CardContainerOuter 
              styles={styles} 
              handleTypeSwitch={handleTypeSwitch} 
              untracked={false} 
              sortedData={sortedData} 
              graphData={graphData}
              setEditing={setEditing}
              setWaitingForResponse={setWaitingForResponse}
              />
            </div>
            }
        </div>
      )    
  }
}

export default Graphs