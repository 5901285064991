import React from 'react'
import styles from '../../style-modules/_sidebar.module.scss'
import settings from '../../assets/settings.svg'
import nutrition from '../../assets/nutrition.svg'
import activity from '../../assets/activity.svg'
import { useNavigate } from 'react-router-dom'

const Sidebar = () => {
  const navigate = useNavigate();
  const goTo = (path) => {
    navigate(`/${path}`)
  }
  return (
    <div className={styles.sidebar}>
        <img src={activity} alt='activity graphs' onClick={() => {goTo('home')}} />
        <img src={nutrition} alt='nutrition' onClick={() => {goTo('#')}} />
        <img src={settings} alt='settings' onClick={() => {goTo('settings')}} />
    </div>
  )
}

export default Sidebar