import React, { useEffect, useState } from 'react'
import styles from '../../style-modules/new/_index.module.scss'
import Form from './Form'

const New = ({graphData, dataToEdit, navigate}) => {
  console.log(dataToEdit)
  const convertToObject = (graphs) => {
    const graphsObject = {};
    return graphs.reduce((obj, item) => {
      return {
        ...obj,
        [item.name]: true,
      };
    }, graphsObject);
  };
  const genSelectDefault = () => {
    if (!dataToEdit.type) {
      return null;
    }
    switch (dataToEdit.type) {
      case 'weight-training' :
        return {value:"weight-training" , label: "Weight Training"}
      case 'calisthenics' :
        return {value:"calisthenics" , label: "Calisthenics"}
      case 'cardio' :
        return {value:"cardio" , label: "Cardio"}
      case 'biometrics' :
        return {value:"biometrics" , label: "Biometrics"}
      default :
        return {value:"other" , label: "Other"}
      }
    }

  return (
    <div className={styles.new}>
        <Form styles={styles} 
        navigate={navigate}
        genSelectDefault={genSelectDefault}
        graphData={graphData} 
        convertToObject={convertToObject}
        dataToEdit={dataToEdit}
        />
    </div>
  )
}

export default New