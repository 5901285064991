import React, { useRef, useState } from 'react'
import Checkbox from './Checkbox';
import NewCheckbox from './NewCheckbox';
import Add from '../sitewide/Add';

const Checklist = ({styles, setWaitingForResponse, waitingForResponse, normalizeToday, today, data, mode}) => {
    console.log(data)
    console.log(data["checks"])
    const [checks, setChecks] = useState(data.checks[`${mode}`])
    const [adding, setAdding] = useState(false)

    const thisRef = useRef(null)

    const updateCheckbox = async (title, content, checklist_ID, checked) => {
      setWaitingForResponse(true)
      const dataToUpdate = {title: title, content: content, checks_ID: checklist_ID, checked: checked, today: today, mode: mode}
      const response = await fetch("https://api.gainstracker.app/home/update_checklist", {
        method: "POST",
        body: JSON.stringify(dataToUpdate),
        credentials: "include",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "https://gainstracker.app/",
        },
      });
      if (response.status !== 200) {
        // setError("The server has encountered an error. Please try creating the graph again.")
        setWaitingForResponse(false)
        return;
      }
      const parsedResponse = await response.json();
      setChecks(parsedResponse)
    }

    const submitCheckbox = async (title, content) => { // adds checkbox to database, returns updated list that will be turned into an array
        setWaitingForResponse(true)
        const dataToSend = {title: title, content: content, today: today, mode: mode}
        const response = await fetch("https://api.gainstracker.app/home/add_checklist", {
          method: "POST",
          body: JSON.stringify(dataToSend),
          credentials: "include",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "https://gainstracker.app/",
          },
        });
        if (response.status !== 200) {
          // setError("The server has encountered an error. Please try creating the graph again.")
          setWaitingForResponse(false)
          return;
        }
        const parsedResponse = await response.json();
        setChecks(parsedResponse)
        setAdding(false)
        setWaitingForResponse(false)
    };

    const deleteCheckbox = async (checklist_ID) => { // adds checkbox to database, returns updated list that will be turned into an array
      setWaitingForResponse(true)
      const dataToSend = {checklist_ID: checklist_ID, today: today}
      const response = await fetch("https://api.gainstracker.app/home/delete_checklist", {
        method: "POST",
        body: JSON.stringify(dataToSend),
        credentials: "include",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "https://gainstracker.app/",
        },
      });
      if (response.status !== 200) {
        // setError("The server has encountered an error. Please try creating the graph again.")
        setWaitingForResponse(false)
        return;
      }
      const parsedResponse = await response.json();
      console.log(parsedResponse)
      setChecks(parsedResponse[`${mode.toLowerCase()}`])
      setAdding(false)
      setWaitingForResponse(false)
  };

  const handleAdding = () => { // changes boolean that determines whether the form shows up or not
    thisRef.current.scrollTo({top: 0, behavior: 'smooth'})
    adding ? setAdding(false) : setAdding(true)
  }

  return (
    <div className={styles.checklist}>
        <div className={styles.checklistHeader}>
          <span>{mode} Checklist</span>
          <Add method={handleAdding} />
        </div>
        <div className={styles.checklistInner} ref={thisRef}>
          {adding ? <NewCheckbox styles={styles} submit={submitCheckbox} handleAdding={handleAdding}/> : ''}
          {checks.map((check) => {return (
              <Checkbox 
              key={check.checklist_ID + check.title + check.description}
              styles={styles} 
              item={check.item} 
              title={check.title}
              checklist_ID={check.checklist_ID} 
              checked={check.checked} 
              updateCheckbox={updateCheckbox}
              deleteCheckbox={deleteCheckbox}
              normalizeToday={normalizeToday}/>
          )})}
        </div>
    </div>
  )
}

export default Checklist