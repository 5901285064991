import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DeleteOverlay from './DeleteOverlay';

function Card({info, styles, convertDate, graphData, setEditing, setWaitingForResponse}) {
  const [error, setError] = useState(null)
  const [showButtons, setShowButtons] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const navigate = useNavigate();

  const handleButtonsActive = (e) => {
    e.stopPropagation();
    showButtons ? setShowButtons(false) : setShowButtons(true)
  }

  const beginEdit = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setEditing(info)
  }
  
  const handleDelete = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setDeleting(true)
  }  

  const deleteGraph = async () => {
    setWaitingForResponse(false)
    const response = await fetch("https://api.gainstracker.app/home/delete_graph", {
      method: "POST",
      body: JSON.stringify({ graph_ID: info.graph_ID }),
      credentials: "include",
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "https://gainstracker.app/",
      },
    });
    if (response.status !== 200) {
      setError("The server encountered an error and could not delete your graph. Please try again.")
      setWaitingForResponse(true)
    } else {
      navigate(0);
    }
  };


  return (
    <div 
      className={styles.card}
      id={deleting ? styles.deleting : ''}
      onClick={() =>
        deleting ? null : navigate("/graph/" + info.graph_ID, { state: { allGraphs: graphData } })
    }>
      {deleting ? <DeleteOverlay styles={styles} deleteGraph={deleteGraph} setDeleting={setDeleting}/> : ''}
      <span className={styles.graphName}>
        {info.name}
        <div className={styles.interface} id={showButtons ? styles.active : ''}>
          <div className={styles.buttons}>
            <span onClick={(e) => handleDelete(e)}>Delete</span>      
            <span onClick={(e) => {beginEdit(e)}}>Edit</span>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" 
          height="1.5em" 
          viewBox="0 -960 960 960" 
          width="1.5em"
          onClick={(e) => handleButtonsActive(e)}
          >
            <path 
            fill={showButtons ? '#000000' : '#79747e'}
            d="M479.788-192Q450-192 429-213.212q-21-21.213-21-51Q408-294 429.212-315q21.213-21 51-21Q510-336 
            531-314.788q21 21.213 21 51Q552-234 530.788-213q-21.213 21-51 21Zm0-216Q450-408 
            429-429.212q-21-21.213-21-51Q408-510 429.212-531q21.213-21 51-21Q510-552 
            531-530.788q21 21.213 21 51Q552-450 530.788-429q-21.213 21-51 21Zm0-216Q450-624 
            429-645.212q-21-21.213-21-51Q408-726 429.212-747q21.213-21 51-21Q510-768 531-746.788q21 21.213 21 
            51Q552-666 530.788-645q-21.213 21-51 21Z"/>
          </svg>
        </div>
      </span>
      <span className={styles.graphDescription}>{info.description}</span>
    </div>
  )
}


export default Card